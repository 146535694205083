import { useAuth } from "@group-link-one/gl-auth";
import {
  GL_COLORS,
  GL_EQUALIZER,
  GL_USER_CHECK,
  GLHeader,
  GLHeaderNavListItemProps,
} from "@group-link-one/grouplink-components";
import { Outlet, useNavigate } from "react-router-dom";

import { useAuthServices } from "../Services/authService/useAuthServices";

interface PageLayoutProps {
  headerNavListItems?: GLHeaderNavListItemProps[];
}

export function PageLayout({ headerNavListItems }: PageLayoutProps) {
  const { user } = useAuth();
  const { logOut } = useAuthServices();
  const navigate = useNavigate();

  const headerNavItemActive = headerNavListItems?.find(
    (navItem) => navItem.path === window.location.pathname
  );

  return (
    <>
      <GLHeader
        onLogOut={() => logOut({})}
        headerNavListItems={headerNavListItems}
        headerNavItemActive={headerNavItemActive?.id}
        user={user!}
        menuOptions={[
          {
            icon: <GL_USER_CHECK size={18} fill={GL_COLORS.FONT_COLOR} />,
            text: "Profile",
            onClick: () => navigate("/settings/overview"),
          },
          {
            icon: <GL_EQUALIZER size={18} fill={GL_COLORS.FONT_COLOR} />,
            text: "Account settings",
            onClick: () => navigate("/settings/my-account"),
          },
        ]}
      />
      <Outlet />
    </>
  );
}
