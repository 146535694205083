import {
  GL_GLStation,
  GL_SETTINGS,
  GLAsideItemProps,
  useGLMenuMobile,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// import { useFlags } from "../../Context/FlagsProvider";
import { verifyIfPathIsActive } from "../../utils/verifyIfPathIsActive";

export enum MainRoutes {
  SETTINGS = "/settings/overview",
  REPORTS = "/reports",
  DEVICE_LIST = "/devices",
  EVENT_LIST = "/network/event-list",
}

export function useMainLayout() {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { handleOpenMenuMobile, isMenuMobileOpen } = useGLMenuMobile();
  // const { hasBetaPrivileges } = useFlags();

  const navListTop: GLAsideItemProps[] = [
    // {
    //   id: 1,
    //   Icon: GL_BAR_CHART,
    //   text: t("pages.reports.title"),
    //   active: verifyIfPathIsActive(MainRoutes.REPORTS),
    //   dropdownItems: [],
    //   onClick: () => {
    //     navigate(MainRoutes.REPORTS);
    //   },
    // },
    // {
    //   id: 3,
    //   Icon: GL_SHARE,
    //   text: t("eventList.subtitle"),
    //   active: verifyIfPathIsActive(MainRoutes.EVENT_LIST, false),
    //   dropdownItems: [],
    //   onClick: () => navigate(MainRoutes.EVENT_LIST),
    // },
  ];

  // if (hasBetaPrivileges()) {
  navListTop.push({
    id: 2,
    Icon: GL_GLStation,
    text: t("deviceList.subtitle"),
    active: verifyIfPathIsActive(MainRoutes.DEVICE_LIST),
    dropdownItems: [],
    onClick: () => {
      navigate(MainRoutes.DEVICE_LIST);
    },
  });
  // }

  const navListBottom: GLAsideItemProps[] = [
    {
      id: 1,
      Icon: GL_SETTINGS,
      text: t("pages.settings.title"),
      active: verifyIfPathIsActive(MainRoutes.SETTINGS, false),
      onClick: () => {
        navigate(MainRoutes.SETTINGS);
      },
    },
  ];

  const removeStyle = () => {
    const container = document.querySelector(".page-animated");
    if (container) {
      container.removeAttribute("style");
    }
  };

  useEffect(() => {
    if (isMenuMobileOpen) {
      handleOpenMenuMobile();
    }

    removeStyle();
  }, [navigate]);

  return { navListTop, navListBottom };
}
