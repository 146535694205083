import { GL_COLORS, GLBox } from "@group-link-one/grouplink-components";
import styled from "styled-components";

export const AlertsContainer = styled(GLBox)`
  overflow-x: auto;
  padding: 10px 0;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${GL_COLORS.FONT_COLOR_DARK_GREY};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${GL_COLORS.DISABLED_BACKGROUND_COLOR};
  }
`;

export const TitleContainer = styled(GLBox).attrs({
  gap: 60,
})`
  @media (max-width: 1190px) {
    gap: 10px !important;
    flex-direction: column !important;
  }
`;

export const InfosContainer = styled(GLBox)`
  @media (max-width: 1190px) {
    /* display: grid !important; */
    /* grid-template-columns: auto auto; */
  }

  @media (max-width: 768px) {
    flex-wrap: wrap !important;
  }
`;
