import {
  GLDeviceList,
  GLPaginationProvider,
} from "@group-link-one/grouplink-components";

import { PageAnimated } from "../../utils/pageAnimated";
import { DeviceListContent } from "./Content/DeviceListContent";
import { ModalDeviceDetails } from "./ModalDeviceDetails/ModalDeviceDetails";
import { useDeviceList } from "./useDeviceList";

export function DeviceList() {
  const { lastPageToken, tabs } = useDeviceList();

  return (
    <GLPaginationProvider lastNextPageToken={lastPageToken} tabs={tabs}>
      <PageAnimated>
        <GLDeviceList
          isOnTopHeaderContent={true}
          allDevicesOptions={{
            deviceListContent: <DeviceListContent />,
          }}
          bigNumbersOptions={{}}
          modalDetails={<ModalDeviceDetails />}
        />
      </PageAnimated>
    </GLPaginationProvider>
  );
}
