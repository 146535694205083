import { useAuth } from "@group-link-one/gl-auth";
import { Fragment } from "react/jsx-runtime";

import { AuthRoutes } from "./auth.routes";
import { AuthenticatedRoutes } from "./authenticated.routes";

export const Routes = () => {
  const { accessToken } = useAuth();

  return (
    <Fragment>
      {accessToken ? <AuthenticatedRoutes /> : <AuthRoutes />}
    </Fragment>
  );
};
