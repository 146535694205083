import { useMediaQuery } from "usehooks-ts";

import { GetDevicesActivatedLastReadingsResponse } from "../../../Services/deviceListService/useDeviceListService.types";
import { useDetailsDeviceStore } from "../store/details-device-store";

export const useDeviceListCard = () => {
  const { actions: detailsDeviceActions } = useDetailsDeviceStore();
  const isMobile = useMediaQuery("(max-width: 1024px)");

  function onAddressClick(lat: number, lng: number) {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(googleMapsUrl, "_blank");
  }

  function onDetailsClick(item: GetDevicesActivatedLastReadingsResponse) {
    detailsDeviceActions.setDevice(item);
    detailsDeviceActions.setModalIsOpen(true);
  }

  return {
    isMobile,
    onAddressClick,
    onDetailsClick,
  };
};
