import { GL_COLORS } from "@group-link-one/grouplink-components";

import { Application } from "../../../Services/deviceListService/useDeviceListService.types";

export function getOptionsByApplication(type?: Application): {
    text: string;
    unit: string;
    color: string;
  } {
    switch (type) {
      case "GLUtilitiesEnergy":
        return {
          text: "Energy",
          unit: "kWh",
          color: GL_COLORS.WARNING,
        };
      // case "GLUtilitiesLight":
      //   return {
      //     text: "Gas",
      //     unit: "m³",
      //     color: GL_COLORS.LEMON_GREEN,
      //   };
      case "GLUtilitiesWater":
        return {
          color: GL_COLORS.ACCENT_COLOR,
          text: "Water",
          unit: "m³",
        };
      default:
        return {
          color: GL_COLORS.ACCENT_COLOR,
          text: "Water",
          unit: "m³",
        };
    }
  }
