import { GLPageNotFound } from "@group-link-one/grouplink-components";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";

import Logo404 from "../images/art_404.svg";
import RightLogo404 from "../images/bg_404.svg";
import GLLogoDark from "../images/Common/logo-glsmartretail-dark.svg";
import GLLogo from "../images/Common/logo-glsmartretail-light.svg";
import { OnboardLayout } from "../layouts/OnboardLayout/OnboardLayout";
import { NewPassword } from "../Pages/Onboard/CreateNewPassword/newPassword";
import { Forgot } from "../Pages/Onboard/Forgot/forgot";
import { Login } from "../Pages/Onboard/Login/login";
import { SignUp } from "../Pages/Onboard/SignUp/signUp";

export const AuthRoutes = () => {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route element={<OnboardLayout />}>
        <Route
          path="/"
          element={
            <>
              <Outlet />
            </>
          }
        >
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Route>
        <Route path="/forgot-password" element={<Forgot />} />
        <Route path="/new-password/:token" element={<NewPassword />} />
        <Route path="/join-organization" element={<SignUp />} />
        {/* <Route path='/verification-code' element={<VerifyCode />} />
      <Route path='/enable-two-factor' element={<EnableTwoFactor />} /> */}

        <Route
          path="*"
          element={
            <GLPageNotFound
              logoOptions={{
                src: {
                  logoLight: GLLogo,
                  logoDark: GLLogoDark,
                },
                backgroundImage: RightLogo404,
                alt: "GroupLink logo",
                logoRight: Logo404,
              }}
              onClickButton={() => navigate("/")}
              title="Oops!"
              subtitle="Page not found"
              description="It looks like you were detached from our IoT world..."
            />
          }
        />
      </Route>
    </Routes>
  );
};
