import {
  GL_COLORS,
  GLLineChartDataProps,
  GLLineChartLineProps,
  useI18n,
} from "@group-link-one/grouplink-components";
import { addDays, differenceInDays, format } from "date-fns";
import { useEffect, useState } from "react";

import { GetDevicesActivatedDailyReadingsRow } from "../../../../../Services/deviceListService/useDeviceListService.types";
import { useDetailsDeviceStore } from "../../../store/details-device-store";
import { getOptionsByApplication } from "../../../utils/getOptionsByApplication";

export const useConsumptionHistory = () => {

  const { t } = useI18n();
  const [lines, setLines] = useState<GLLineChartLineProps[]>([]);

  const { state: detailsDeviceState } = useDetailsDeviceStore();

  function formatData(readings?: GetDevicesActivatedDailyReadingsRow[]) {
    if (
      !readings ||
      !detailsDeviceState.currentData ||
      !detailsDeviceState.range.from ||
      !detailsDeviceState.range.to
    )
      return [];

    const countAllDays = differenceInDays(
      detailsDeviceState.range.to,
      detailsDeviceState.range.from
    );

    const arrayDate: GLLineChartDataProps[] = Array.from(
      { length: countAllDays + 1 },
      (_, index) => {
        return {
          x: 0,
          label: format(
            addDays(new Date(detailsDeviceState.range.from!), index),
            "MM/dd/yyyy"
          ),
          unit: detailsDeviceState.device?.channels[0].unit,
        };
      }
    );

    const formattedData: GLLineChartDataProps[] = arrayDate.map((reading) => {
      const hasDateIntersection = readings.find(
        (dailyReadingData) =>
          format(new Date(dailyReadingData.time), "MM/dd/yyyy") ===
          reading.label
      );

      if (hasDateIntersection) {
        return {
          label: format(new Date(reading.label), "MM/dd/yyyy"),
          x: hasDateIntersection.reading,
          // unit: detailsDeviceState.device?.channels[0].unit,
          unit: getOptionsByApplication(detailsDeviceState.device?.application).unit,
        };
      } else {
        return {
          label: format(new Date(reading.label), "MM/dd/yyyy"),
          x: 0,
          // unit: detailsDeviceState.device?.channels[0].unit,
          unit: getOptionsByApplication(detailsDeviceState.device?.application).unit,
        };
      }
    });

    formatLines(formattedData);
  }

  function formatLines(lineChartData: GLLineChartDataProps[]) {
    const deviceActivatedAt = format(
      new Date(detailsDeviceState.device?.channels[0].activated_at || ""),
      "MM/dd/yyyy"
    );

    const hasPeriodPreActivation = lineChartData.map(item => {
      return differenceInDays(new Date(item.label), new Date(deviceActivatedAt)) < 0
    }).some(item => item)

    if (hasPeriodPreActivation) {
      setWhenHasPeriodPreActivation(lineChartData)
    } else {
      setWhenHasNoPeriodPreActivation(lineChartData)
    }

  }

  function setWhenHasPeriodPreActivation(lineChartData: GLLineChartDataProps[]) {
    const dayWithAFirstValue = lineChartData.findIndex((item) => item.x !== 0);

    const daysWithNoValue = lineChartData
      .slice(0, dayWithAFirstValue)
      .map((item) => ({ ...item, type: "disabled", y: 0 }));

    const daysWithValue = lineChartData
      .slice(dayWithAFirstValue)
      .map((item) => ({ ...item, type: "active" }));

    if (dayWithAFirstValue === -1) {
      const newLines: GLLineChartLineProps[] = [
        {
          dataKey: "y",
          name: t("deviceList.detailsModal.ut.graphs.one.legends.pre"),
          stroke: GL_COLORS.DISABLED_FONT_COLOR,
          type: "disabled",
          data: daysWithNoValue
        },
        {
          dataKey: "x",
          name: t("deviceList.detailsModal.ut.graphs.one.legends.post"),
          animationBeginInSeconds: 1,
          stroke: getOptionsByApplication(detailsDeviceState.device?.application).color,
          type: "active",
          data: undefined
        },
      ]

      setLines(newLines);
      return
    }

    const periodPreActivation = formatPeriodPreActivation(daysWithNoValue, daysWithValue)
    const newLines: GLLineChartLineProps[] = [
      {
        dataKey: "y",
        name: t("deviceList.detailsModal.ut.graphs.one.legends.pre"),
        stroke: GL_COLORS.DISABLED_FONT_COLOR,
        type: "disabled",
        data: periodPreActivation
      },
      {
        dataKey: "x",
        name: t("deviceList.detailsModal.ut.graphs.one.legends.post"),
        animationBeginInSeconds: 1,
        stroke: getOptionsByApplication(detailsDeviceState.device?.application).color,
        type: "active",
        data: daysWithValue
      },
    ]

    setLines(newLines);
  }

  function formatPeriodPreActivation(
    preActivationData: GLLineChartDataProps[],
    postActivationData: GLLineChartDataProps[]
  ) {

    if (!preActivationData || preActivationData.length === 0) return [];

    if (preActivationData.length > 0 && postActivationData.length > 0) {

      const firstItemOfPostActivation = postActivationData[0];

      return [
        ...preActivationData,
         {
          ...firstItemOfPostActivation,
          type: "disabled",
          y: firstItemOfPostActivation.x
        }
       ]
    }

    if (preActivationData.length > 0 && postActivationData.length === 0) {
      return preActivationData;
    }
  }

  function setWhenHasNoPeriodPreActivation(lineChartData: GLLineChartDataProps[]) {
    setLines([
      {
        dataKey: "x",
        name: t("deviceList.detailsModal.ut.graphs.one.legends.post"),
        stroke: getOptionsByApplication(detailsDeviceState.device?.application).color,
        type: "active",
        data: lineChartData
      },
      {
        dataKey: "y",
        name: t("deviceList.detailsModal.ut.graphs.one.legends.pre"),
        stroke: GL_COLORS.DISABLED_FONT_COLOR,
        type: "disabled",
        data: undefined
      },
    ]);
  }

  useEffect(() => {
    formatData(detailsDeviceState.currentData);
  }, [detailsDeviceState.currentData]);

  return {
    lines,
    detailsDeviceState,
    isLoading: detailsDeviceState.isLoading,
  };
};
