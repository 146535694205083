import { GLBarChart, GLBoxChart, useI18n } from "@group-link-one/grouplink-components";

import { getOptionsByApplication } from "../../../utils/getOptionsByApplication";
import { useDailyConsumption } from "./useDailyConsumption";

export function GraphDailyConsumption() {
  const { bars, data, average, detailsDeviceState, isLoading } = useDailyConsumption();
  const { t } = useI18n();

  return (
    <GLBoxChart
      title={t("deviceList.detailsModal.ut.graphs.two.title")}
      textSwitcherId="daily-consumption-history-switcher"
      tooltipText={t("deviceList.detailsModal.ut.graphs.two.tooltipDescription")}
      isLoading={isLoading}
      chart={
        <GLBarChart
          data={data}
          bars={bars}
          average={average > 0 ? average : undefined}
          legendOptions={{
            wrapperStyle: {
              top: -24,
            },
          }}
          yAxisOptions={{
            tickFormatter: (value: string) =>
              `${Number(value).toFixed(2)} ${getOptionsByApplication(detailsDeviceState.device?.application).unit}`,
          }}
          xAxisOptions={{
            padding: {
              left: 40,
              right: 70,
            },
          }}
          isLoading={isLoading}
          hasLegend={false}
        />
      }
    />
  );
}
