import { useAuth } from "@group-link-one/gl-auth";
import {
  countryList,
  useI18n,
  useMyAccountStore,
  useToast,
} from "@group-link-one/grouplink-components";
import parsePhoneNumber from "libphonenumber-js";
import { useEffect } from "react";

import {
  MyAccForm,
  useMyAccountService,
} from "../../../Services/myAccountService/useMyAccountService";

export function useMyAccount() {
  const { user, manipulateReduxValues } = useAuth();
  const { addToast } = useToast();
  const { t } = useI18n();
  const { updateMyAccount, getMyAccount } = useMyAccountService();

  const {
    // state: myAccountState,
    actions: myAccountStoreActions,
  } = useMyAccountStore();

  const handleUpdateAccountInformation = async (data: MyAccForm) => {
    myAccountStoreActions.setIsSubmitting(true);

    // const phoneFormatted = formatPhoneNumber(data.phone);

    const options = {
      ...data,
      phone: data.phone?.replace(/\D/g, ""),
    };

    updateMyAccount(options)
      .then((res) => {
        if (res.status === 204) {
          addToast({
            title: t("myAccount.toast.title"),
            message: t("myAccount.toast.message"),
            type: "success",
          });
        }
        getMyAccount().then((res_) => {
          manipulateReduxValues("SET_USER", res_.data.session);
        });
      })
      .catch(() => {
        addToast({
          title: t("toast.error.title"),
          message: t("toast.error.message"),
          type: "error",
        });
      })
      .finally(() => {
        myAccountStoreActions.setIsSubmitting(false);
      });
  };

  // function formatPhoneNumber(phone?: string | null) {
  //   if (!phone || !myAccountState.countryActive) {
  //     return "";
  //   }

  //   const phoneWithDDD = `${myAccountState.countryActive.phone}${phone}`;

  //   return "+" + phoneWithDDD.replace(/\D/g, "");
  // }

  useEffect(() => {
    const BR = countryList.find((country) => country.code === "BR");
    const phoneNumber = parsePhoneNumber(user?.phone || "");

    if (!phoneNumber) return myAccountStoreActions.setCountryActive(BR);

    const ddd = phoneNumber.countryCallingCode.toString();
    const countryActive = countryList.find(
      (country) => String(country.phone) === ddd
    );

    countryActive
      ? myAccountStoreActions.setCountryActive(countryActive)
      : myAccountStoreActions.setCountryActive(BR);
  }, [user]);

  return {
    handleUpdateAccountInformation,
    user,
  };
}
