import {
  BigNumberProps,
  Colors,
  GL_CHECK,
  GL_COLORS,
  GL_GLStation,
  GL_LOGIN,
  TabProps,
} from "@group-link-one/grouplink-components";

import CheckImg from "../../images/DeviceList/icon-check.svg";
import InternetImg from "../../images/DeviceList/icon-internet.svg";
import LoginImg from "../../images/DeviceList/icon-log-in.svg";
import StationImg from "../../images/DeviceList/icon-station.svg";
import { useDetailsDeviceStore } from "./store/details-device-store";

export const useDeviceList = () => {
  const { state } = useDetailsDeviceStore();

  const tabs: TabProps[] = [
    {
      id: 1,
      active: true,
      cacheKey: "all-devices",
      count: 0,
      name: "All devices",
      onClick: () => {},
    },
    // {
    //   id: 2,
    //   active: false,
    //   cacheKey: "pending-devices",
    //   count: 0,
    //   name: "Pending registration",
    //   onClick: () => {},
    // },
  ];

  const lastPageToken = {
    "all-devices": {
      token: undefined,
      page: undefined,
    },
    // "pending-devices": {
    //   token: undefined,
    //   page: undefined,
    // },
  };

  const bigNumbers: BigNumberProps[] = [
    {
      iconLeft: (
        <GL_GLStation
          fillOne={GL_COLORS.FONT_COLOR_VARIANT}
          fill={GL_COLORS.FONT_COLOR_VARIANT}
          size={24}
        />
      ),
      iconRight: <img src={StationImg} alt="" />,
      iconRightStyle: {
        bottom: -20,
      },
      title: "Total devices",
      count: 0,
      backgroundColor: GL_COLORS.ACCENT_COLOR,
      textColor: "FONT_COLOR_VARIANT",
    },
    {
      iconLeft: <GL_LOGIN fill={GL_COLORS.WHITE} size={24} />,
      iconRight: <img src={LoginImg} alt="" />,
      iconRightStyle: {
        bottom: -20,
      },
      title: "Total audience",
      count: 0,
      backgroundColor: Colors.NAVY,
      textColor: "WHITE",
    },
    {
      iconLeft: <GL_CHECK fill={GL_COLORS.NAVY} size={24} />,
      iconRight: <img src={CheckImg} alt="" />,
      iconRightStyle: {
        bottom: 0,
      },
      title: "Registered devices",
      count: 0,
      backgroundColor: Colors.LIGHT_GREEN,
      textColor: "NAVY",
    },
    {
      iconLeft: (
        <GL_GLStation
          fillOne={GL_COLORS.WHITE}
          fill={GL_COLORS.WHITE}
          size={24}
        />
      ),
      iconRight: <img src={InternetImg} alt="" />,
      iconRightStyle: {
        bottom: 0,
      },
      title: "Total Devices",
      count: 0,
      backgroundColor: Colors.PURPLE,
      textColor: "WHITE",
    },
  ];

  return {
    tabs,
    lastPageToken,
    bigNumbers,
    isOpen: state.modalIsOpen,
  };
};
