import { AuthProvider, persistor, store } from "@group-link-one/gl-auth";
import { SmartRetailTheme } from "@group-link-one/grouplink-components";
import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import { env } from "./env";
import { queryClient } from "./Services/queryClient";
import { analytics } from "./Services/remoteConfig";

analytics;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthProvider baseURL={env.REACT_APP_API_URL}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <SmartRetailTheme />
            <App />
          </BrowserRouter>
        </QueryClientProvider>
      </AuthProvider>
    </PersistGate>
  </Provider>
);
