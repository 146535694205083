import {
  AlertType,
  formatValueSize,
  GL_ALARM,
  GL_ARROW_TRIANGLE_LEFT_FILL,
  GL_CHEVRON_DOWN,
  GL_COLORS,
  GL_PIN,
  GLBox,
  GLButton,
  GLDropdown,
  GLTooltip,
  GLTypography,
} from "@group-link-one/grouplink-components";
import { format } from "date-fns";
import { motion } from "framer-motion";

import { getOptionsByApplication } from "../../utils/getOptionsByApplication";
import { AlertsContainer, InfosContainer, TitleContainer } from "./Style";
import { useDeviceDetailsTitleContent } from "./useDeviceDetailsTitleContent";

export function DeviceDetailsTitleContent() {
  const {
    device,
    langActive,
    userUseCase,
    currentChannelValue,
    currentChannelData,
    detailsDeviceState,
    t,
    onAddressClick,
    is1190px,
    alertsMap,
    isMobile,
    onChannelChange
  } = useDeviceDetailsTitleContent();

  const boxInfoStyle: React.CSSProperties = {
    borderRadius: 15,
    backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
    padding: "15px 20px",
  };

  return (
    <GLBox
      width="100%"
      animation="fade-in-down"
      align="center"
      direction="column"
      transition={{
        delay: 0.3,
        duration: 1.4,
      }}
    >
      <TitleContainer>
        <GLBox direction="column" justify="flex-start" gap={5} width="auto">
          <GLTypography
            as="p"
            text={currentChannelData?.name || ""}
            color="FONT_COLOR"
            weight={600}
            fontSize={6.5}
            style={{ whiteSpace: "nowrap" }}
          />
          <GLTypography
            as="span"
            text={`ID ${device?.device_id || ""}`}
            color="DISABLED_FONT_COLOR"
            weight={600}
            fontSize={3}
            style={{ whiteSpace: "nowrap" }}
          />
        </GLBox>

        <GLBox>
          <GLTooltip
            text={
              device &&
                device?.meta?.display_address?.length &&
                device?.meta.display_address.length > 70
                ? device.meta.display_address
                : undefined
            }
          >
            <GLButton
              text={
                formatValueSize({
                  size: 70,
                  value: device?.meta.display_address || "",
                }) || "--"
              }
              variant="outline"
              align="left"
              border={false}
              weight={600}
              colorIconDynamically={true}
              icon={<GL_PIN size={16} fill={getOptionsByApplication(device?.application).color} />}
              color={getOptionsByApplication(device?.application).color}
              style={{ width: "fit-content", textAlign: "left" }}
              onClick={onAddressClick}

            />
          </GLTooltip>
        </GLBox>
      </TitleContainer>

      {device && currentChannelData && currentChannelData.alerts.length > 0 && (
        <GLBox align="center" gap={20}>
          <GLTypography
            text="SYSTEM ALARMS"
            color="FONT_COLOR_DARK_GREY"
            as="span"
            fontSize={3.5}
            weight={600}
            style={{ whiteSpace: "nowrap" }}
          />

          <GL_ARROW_TRIANGLE_LEFT_FILL
            size={12}
            fill={GL_COLORS.DISABLED_FONT_COLOR}
          />

          <AlertsContainer>
            {currentChannelData.alerts.map((item, index) => (
              <motion.div
                key={index}
                initial={{
                  opacity: 0,
                  x: -20,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                }}
                transition={{
                  duration: 0.5,
                  delay: index * 0.15,
                }}
              >
                <GLBox
                  key={index}
                  align="center"
                  justify="center"
                  width="fit-content"
                  style={{
                    borderRadius: 20,
                    padding: "10px 15px",
                    border: `2px solid ${GL_COLORS.BORDER_COLOR}`,
                    backgroundColor: GL_COLORS.DANGER,
                    minWidth: 100,
                  }}
                >
                  <GL_ALARM size={16} fill={GL_COLORS.WHITE} />

                  <GLTypography
                    text={alertsMap[item as AlertType] || ""}
                    color="WHITE"
                    as="span"
                    fontSize={3.5}
                    weight={600}
                    style={{ whiteSpace: "nowrap" }}
                  />
                </GLBox>
              </motion.div>
            ))}
          </AlertsContainer>
        </GLBox>
      )}

      <GLBox
        align={isMobile ? "flex-start" : "center"}
        justify={isMobile ? "flex-start" : "space-between"}
        direction={isMobile ? "column" : "row"}
        gap={20}
      >
        <InfosContainer>
          <GLBox width="fit-content" style={{ ...boxInfoStyle }}>
            <GLTypography
              text="REMOTE ID"
              color="FONT_COLOR_DARK_GREY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
            />
            <GLTypography
              text={currentChannelData?.remote_id || ""}
              color="TITLE_COLOR_SECONDARY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
            />
          </GLBox>

          {userUseCase === "util_water" && (
            <>
              <GLBox width="fit-content" style={{ ...boxInfoStyle }}>
                <GLTypography
                  text="OFFSET"
                  color="FONT_COLOR_DARK_GREY"
                  as="span"
                  fontSize={is1190px ? 3 : 3.5}
                  weight={600}
                />
                <GLTypography
                  text={String(currentChannelData?.device_offset) || ""}
                  color="TITLE_COLOR_SECONDARY"
                  as="span"
                  fontSize={is1190px ? 3 : 3.5}
                  weight={600}
                />
              </GLBox>

              <GLBox width="fit-content" style={{ ...boxInfoStyle }}>
                <GLTypography
                  // text={`${currentChannelData?.unit || ""} / PULSO`}
                  text={`${getOptionsByApplication(detailsDeviceState.device?.application).unit || ""} / PULSO`}
                  color="FONT_COLOR_DARK_GREY"
                  as="span"
                  fontSize={is1190px ? 3 : 3.5}
                  weight={600}
                  style={{ textTransform: "uppercase" }}
                />
                <GLTypography
                  text={String(currentChannelData?.device_pulse_factor) || ""}
                  color="TITLE_COLOR_SECONDARY"
                  as="span"
                  fontSize={is1190px ? 3 : 3.5}
                  weight={600}
                />
              </GLBox>
            </>
          )}

          <GLBox width="fit-content" style={{ ...boxInfoStyle }}>
            <GLTypography
              text={t("deviceList.detailsModal.activatedAt")}
              color="FONT_COLOR_DARK_GREY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
              style={{ textTransform: "uppercase" }}
            />
            <GLTypography
              text={
                device
                  ? format(new Date(currentChannelData?.activated_at || ""), "dd/MM/yyyy", {
                    locale: langActive,
                  })
                  : ""
              }
              color="TITLE_COLOR_SECONDARY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
            />
          </GLBox>
        </InfosContainer>

        <GLDropdown.Root width="130px">
          <GLDropdown.Input
            width="100%"
            icon={<GL_CHEVRON_DOWN size={12} fill={GL_COLORS.DISABLED_FONT_COLOR} />}
            value={currentChannelValue}
            valueOptions={{
              whiteSpace: "nowrap",
              fontWeight: 600,
            }}
          />

          <GLDropdown.BoxOptions width="100%">
            {detailsDeviceState.device?.channels?.map((channel, index) => (
              <GLDropdown.Option
                key={index}
                text={`Channel ${channel.channel + 1}`}
                value={`Channel ${channel.channel + 1}`}
                hasCheckInActive={false}
                onClick={(value) => {
                  onChannelChange(value)
                }}
              />

            ))}
          </GLDropdown.BoxOptions>
        </GLDropdown.Root>
      </GLBox>
    </GLBox>
  );
}
