import { useAuth } from "@group-link-one/gl-auth";
import {
  GLLoadingPage,
  GLPageNotFound,
  useGLModalLogout,
} from "@group-link-one/grouplink-components";
import { AnimatePresence } from "framer-motion";
import { useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useFlags } from "../Context/FlagsProvider";
import Logo404 from "../images/art_404.svg";
import RightLogo404 from "../images/bg_404.svg";
import GLLogoDark from "../images/Common/logo-glutilities-dark.svg";
import GLLogo from "../images/Common/logo-glutilities-light.svg";
// import { EventListLayout } from "../layouts/EventListLayout/EventListLayout";
import { MainLayout } from "../layouts/MainLayout/MainLayout";
import { PageLayout } from "../layouts/PageLayout";
import { SettingsLayout } from "../layouts/SettingsLayout/SettingsLayout";
// import { SettingsLayout } from "../layouts/SettingsLayout/SettingsLayout";
import { DeviceList } from "../Pages/DeviceList/DeviceList";
// import { EventList } from "../Pages/EventList/eventList";
// import { Reports } from "../Pages/ReportList/ReportList";
import { Apps } from "../Pages/Settings/Apps/apps";
import { LoginSecurity } from "../Pages/Settings/LoginSecurity/loginSecurity";
import { MyAccount } from "../Pages/Settings/MyAccount/myAccount";
import { Organizations } from "../Pages/Settings/Organizations/organizations";
import { Overview } from "../Pages/Settings/Overview/overview";
import { Users } from "../Pages/Settings/Users/organizationUsers";

export const AuthenticatedRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isPending, manipulateReduxValues } = useAuth();
  const { hasPrivileges, user } = useFlags();

  const { handleOpenModalLogout } = useGLModalLogout();

  const indexRoute = useMemo(() => {
    return hasPrivileges(["apps_get"])
      ? "/settings/overview" // TODO
      : "/settings/overview";
  }, [user]);

  useEffect(() => {
    manipulateReduxValues("SET_ACTION_ON_IDLE", handleOpenModalLogout);
  }, []);

  if (isPending || !user) return <GLLoadingPage />;

  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<MainLayout />}>
          {/* <Route path="/network" element={<EventListLayout />}>
            <Route path="event-list" element={<EventList />} />
          </Route> */}

          {/* <Route path="/reports" element={<PageLayout />}>
            <Route path="/reports" element={<Reports />} />
          </Route> */}

          <Route path="/devices" element={<PageLayout />}>
            {/* {hasBetaPrivileges() && <Route index element={<DeviceList />} />} */}
            <Route index element={<DeviceList />} />
          </Route>

          <Route path="/settings" element={<SettingsLayout />}>
            <Route
              path="/settings"
              element={<Navigate to="/settings/overview" />}
            />
            <Route path="overview" element={<Overview />} />
            <Route path="my-account" element={<MyAccount />} />
            <Route path="login-security" element={<LoginSecurity />} />
            <Route path="users" element={<Users />} />
            <Route path="organizations" element={<Organizations />} />
            <Route path="apps" element={<Apps />} />
          </Route>

          <Route path="/" element={<Navigate to={indexRoute} />} />

          <Route
            path="*"
            element={
              <GLPageNotFound
                logoOptions={{
                  src: {
                    logoLight: GLLogo,
                    logoDark: GLLogoDark,
                  },
                  backgroundImage: RightLogo404,
                  alt: "GroupLink logo",
                  logoRight: Logo404,
                }}
                onClickButton={() => navigate("/")}
                title="Oops!"
                subtitle="Page not found"
                description="It looks like you were detached from our IoT world..."
              />
            }
          />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};
