import { GLBoxChart, GLLineChart, useI18n } from "@group-link-one/grouplink-components";

import { getOptionsByApplication } from "../../../utils/getOptionsByApplication";
import { useConsumptionHistory } from "./useConsumptionHistory";

export function GraphConsumptionHistory() {
  const { lines, detailsDeviceState, isLoading } = useConsumptionHistory();
  const { t } = useI18n();

  return (
    <GLBoxChart
      title={t("deviceList.detailsModal.ut.graphs.one.title")}
      textSwitcherId="consumption-history-switcher"
      tooltipText={t("deviceList.detailsModal.ut.graphs.one.tooltipDescription")}
      isLoading={isLoading}
      chart={
        <GLLineChart
          lines={lines}
          type="multi-series"
          xAxisOptions={{
            allowDuplicatedCategory: false,
            type: "category"
          }}
          yAxisOptions={{
            tickFormatter: (value: string) =>
              `${Number(value).toFixed(2)} ${getOptionsByApplication(detailsDeviceState.device?.application).unit}`,
          }}
          isLoading={isLoading}
        />
      }
    />
  );
}
