import { PaginateResponseProps } from "@group-link-one/grouplink-components";

import useAxiosHTTPRequest from "../useAxiosHTTPRequest";
import {
  GetDevicesActivatedDailyReadingsParams,
  GetDevicesActivatedDailyReadingsResponse,
  GetDevicesActivatedLastReadingsParams,
  GetDevicesActivatedLastReadingsResponse,
  GetDevicesCountParams,
  GetDevicesCountResponse,
} from "./useDeviceListService.types";

export const useDeviceListService = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const getDevicesActivatedLastReadings = async ({
    ...params
  }: GetDevicesActivatedLastReadingsParams): Promise<
    PaginateResponseProps<GetDevicesActivatedLastReadingsResponse[]>
  > => {
    const response = await httpPrivate.get("/devices-activated/last-reading", {
      params,
    });

    return response.data;
  };

  const getDevicesActivatedDailyReadings = async ({
    ...params
  }: GetDevicesActivatedDailyReadingsParams): Promise<GetDevicesActivatedDailyReadingsResponse> => {
    const response = await httpPrivate.get("/devices-activated/daily-reading", {
      params,
    });

    return response.data;
  };

  const getDevicesCount = async (
    params?: GetDevicesCountParams
  ): Promise<GetDevicesCountResponse> => {
    const response = await httpPrivate.get("/device-activation/count", {
      params,
    });

    return response.data;
  };

  const getDevicesActivateds = async (): Promise<any> => {
    const response = await httpPrivate.get("/device-activation/activated");
    return response.data;
  };

  return {
    getDevicesActivatedLastReadings,
    getDevicesActivatedDailyReadings,
    getDevicesCount,
    getDevicesActivateds,
  };
};
