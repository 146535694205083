import { useAuth } from "@group-link-one/gl-auth";
import {
  AlertType,
  DateRange,
  useActiveLanguageDateFns,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";

import { UseCase } from "../../../EventList/Content/Columns/AllColumns";
import { useDetailsDeviceStore } from "../../store/details-device-store";

export const useDeviceDetailsTitleContent = () => {
  const { state: detailsDeviceState, actions: detailsDeviceActions } =
    useDetailsDeviceStore();
  const { user } = useAuth();

  const { t } = useI18n();

  const isMobile = useMediaQuery("(max-width: 1024px)");
  const langActive = useActiveLanguageDateFns();

  const is1190px = useMediaQuery("(max-width: 1190px)");

  const userUseCase = user?.use_case as UseCase;

  const alertsMap = {
    CALIBRATING: "Calibrating",
    CAR_PLUGGED: "Car plugged",
    LEAKAGE: "Leakage",
    MAGNETIC_FRAUDULENCE: "Magnetic fraud",
    MAX_FLOW: "Max flow",
    OPEN_DOOR: "Open door",
    OUT_OF_RANGE: "Out of range",
    REVERSE_FLOW: "Reverse flow",
    VIOLATION: "Violation",
    WIRE_CUTTING: "Wire cutting",
  } as Record<AlertType, string>;

  const currentChannelValue = useMemo(() => {
    const channelsMap = {
      "channel_0": "Channel 1",
      "channel_1": "Channel 2",
    }

    return channelsMap[`channel_${detailsDeviceState.channelActive || 0}` as keyof typeof channelsMap]
  }, [detailsDeviceState.device?.channels, detailsDeviceState.channelActive])

  const currentChannelData = useMemo(() => {
    return detailsDeviceState.device?.channels[detailsDeviceState.channelActive || 0]
  }, [detailsDeviceState.channelActive])

  function onAddressClick() {
    if (!detailsDeviceState.device) return;

    const lat = detailsDeviceState.device.meta.latitude;
    const lng = detailsDeviceState.device.meta.longitude;

    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(googleMapsUrl, "_blank");
  }

  function onDateChange(range: DateRange) {
    detailsDeviceActions.setRange(range);
  }

  function onChannelChange(channel?: string) {
    if (!channel) return;

    const channelClicked = Number(channel.split(" ")[1]) - 1;

    detailsDeviceActions.setChannelActive(channelClicked);

    if (channelClicked === 0) {
      detailsDeviceActions.setCurrentData(
        detailsDeviceState.channelZero || []
      )
    }

    if (channelClicked === 1) {
      detailsDeviceActions.setCurrentData(
        detailsDeviceState.channelOne || []
      )
    }

  }

  function hasAlarm(alarm: AlertType) {
    return detailsDeviceState.device?.channels[0].alerts.includes(alarm);
  }

  return {
    device: detailsDeviceState.device,
    range: detailsDeviceState.range,
    userUseCase,
    currentChannelValue,
    currentChannelData,
    detailsDeviceState,
    detailsDeviceActions,
    t,
    hasAlarm,
    langActive,
    alertsMap,
    isMobile,
    is1190px,
    onAddressClick,
    onDateChange,
    onChannelChange
  };
};
