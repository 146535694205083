import {
  GLHeaderNavListItemProps,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useMemo } from "react";

import { useFlags } from "../../Context/FlagsProvider";

export enum SettingsRoutes {
  DEFAULT = "/settings",
  OVERVIEW = "/settings/overview",
  MY_ACCOUNT = "/settings/my-account",
  LOGIN_SECURITY = "/settings/login-security",
  USERS = "/settings/users",
  ORGANIZATIONS = "/settings/organizations",
  APPS = "/settings/apps",
}

export function useSettingsLayout() {
  const { t } = useI18n();
  const { flags, hasPrivileges, user } = useFlags();

  const headerNavList: GLHeaderNavListItemProps[] = useMemo(() => {
    const items = [
      {
        id: 1,
        title: t("pages.deviceOverview.overview"),
        path: SettingsRoutes.OVERVIEW,
      },
      {
        id: 2,
        title: t("pages.settings.myAccount"),
        path: SettingsRoutes.MY_ACCOUNT,
      },
      {
        id: 3,
        title: t("pages.settings.loginSecurity"),
        path: SettingsRoutes.LOGIN_SECURITY,
      },
    ];

    if (hasPrivileges(["apps_get"])) {
      items.push({
        id: 6,
        title: t("pages.settings.apps"),
        path: SettingsRoutes.APPS,
      });
    }

    if (hasPrivileges(["organization_user_get"])) {
      items.push({
        id: 4,
        title: t("users.subtitle"),
        path: SettingsRoutes.USERS,
      });
    }

    return items;
  }, [flags, user]);

  const headerNavItemActive = headerNavList.find(
    (navItem) => navItem.path === window.location.pathname
  );

  return {
    headerNavList,
    headerNavItemActive,
  };
}
