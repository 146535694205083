import {
  DateRange,
  GLBarChartDataProps,
} from "@group-link-one/grouplink-components";
import { useQueryClient } from "@tanstack/react-query";
import { addDays, differenceInDays, format } from "date-fns";

import { useDeviceListService } from "../../../../Services/deviceListService/useDeviceListService";
import { GetDevicesActivatedDailyReadingsRow } from "../../../../Services/deviceListService/useDeviceListService.types";
import { useDetailsDeviceStore } from "../../store/details-device-store";

interface GetDailyReadingsParams {
  range: DateRange;
  device_id: number;
}

export const useGetDailyReadings = () => {
  const queryClient = useQueryClient();

  const { state: deviceDetailsState, actions: deviceDetailsActions } = useDetailsDeviceStore();
  const { getDevicesActivatedDailyReadings } = useDeviceListService();

  async function getDailyReadings({
    device_id,
    range,
  }: GetDailyReadingsParams) {
    try {
      deviceDetailsActions.setIsLoading(true);

      const response = await queryClient.fetchQuery({
        queryKey: [
          "deviceList",
          "getDevicesActivatedDailyReadings",
          device_id,
          range.from,
          range.to,
        ],
        queryFn: async () => {
          return await getDevicesActivatedDailyReadings({
            device_id: Number(device_id),
            since: format(new Date(range.from), "yyyy-MM-dd"),
            until: format(new Date(range.to), "yyyy-MM-dd"),
            object_readings: true,
          });
        },
        staleTime: 1000 * 60 * 5,
      });

      const channelZero = response?.rows.filter(
        (reading) => reading.channel === 0
      );

      const channelOne = response?.rows.filter(
        (reading) => reading.channel === 1
      );

      deviceDetailsActions.setChannelZero(channelZero);
      deviceDetailsActions.setChannelOne(channelOne);

      deviceDetailsState.channelActive === 0
        ? deviceDetailsActions.setCurrentData(channelZero)
        : deviceDetailsActions.setCurrentData(channelOne);

      return {
        response,
        channelZero,
        channelOne,
      };
    } catch (error) {
      console.error(error);
    } finally {
      deviceDetailsActions.setIsLoading(false);
    }
  }

  function getAverage(data: GLBarChartDataProps[]) {
    const values = data.map((item) => item.x || 0);
    const average = values.reduce((a, b) => a + b, 0) / values.length;

    return average;
  }

  function formatReadings(
    readings: GetDevicesActivatedDailyReadingsRow[] | undefined
  ): {
    readingsFormatted: GetDevicesActivatedDailyReadingsRow[];
    arrayDate: GLBarChartDataProps[];
    countAllDays: number;
  } {
    if (!deviceDetailsState.range.to ||
      !deviceDetailsState.range.from ||
      !readings
    ) return { readingsFormatted: [], arrayDate: [], countAllDays: 0 }

    const countAllDays = differenceInDays(
      deviceDetailsState.range.to,
      deviceDetailsState.range.from
    );

    const arrayDate: GLBarChartDataProps[] = Array.from(
      { length: countAllDays + 1 },
      (_, index) => {
        return {
          x: 0,
          label: format(
            addDays(new Date(deviceDetailsState.range.from!), index),
            "MM/dd/yyyy"
          ),
          unit: deviceDetailsState.device?.channels[0].unit,
        };
      }
    );

    const readingsFormatted: GetDevicesActivatedDailyReadingsRow[] = arrayDate.map(item => {

      const findReadingByDate = readings?.find(
        reading =>
          format(new Date(reading.time), "MM/dd/yyyy") === item.label
      );

      return {
        channel: findReadingByDate?.channel || deviceDetailsState.channelActive || 0,
        reading: findReadingByDate?.reading || 0,
        time: item.label
      }
    })

    return { readingsFormatted, arrayDate, countAllDays };
  }

  return { getDailyReadings, getAverage, formatReadings };
};
