import { useGLPagination } from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";

import { useDeviceListService } from "../../../Services/deviceListService/useDeviceListService";

export const DEVICE_LIST_IPP = 50;

export const useDeviceListAllDevices = () => {
  const { getDevicesActivatedLastReadings, getDevicesCount } =
    useDeviceListService();

  const { state: paginationState, actions: paginationActions } =
    useGLPagination();

  const activeTab = useMemo(() => {
    return paginationActions.getActiveTabById(paginationState.activeTabId);
  }, [paginationState.activeTabId]);

  const searchDeviceID = useMemo(() => {
    if (paginationState.search.length === 0) return undefined;

    return isNaN(Number(paginationState.search))
      ? undefined
      : [Number(paginationState.search)];
  }, [paginationState.search]);

  const { data: devicesCount } = useQuery({
    queryKey: ["devices-count", paginationState.search],
    queryFn: async () => {
      return await getDevicesCount({
        device_id: searchDeviceID ? searchDeviceID[0] : undefined,
      });
    },
  });

  const {
    data: deviceList,
    isLoading: deviceListIsLoading,
    isError: deviceListIsErrored,
  } = useQuery({
    queryKey: [
      "devices-activated-last-readings",
      paginationState.currentPage,
      paginationState.search,
    ],
    queryFn: async () => {
      const { nextPageToken, optionsToStoreNextPageToken } =
        paginationActions.getNextPageToken();

      const response = await getDevicesActivatedLastReadings({
        ipp: DEVICE_LIST_IPP,
        next_page_token: nextPageToken,
        device_id: searchDeviceID,
        object_readings: true,
        application: "GLTracking"
      });

      paginationActions.setNextPageToken({
        hasMore: response.has_more,
        nextPageToken: response.next_page_token || undefined,
        optionsToStoreNextPageToken,

      });

      return response.rows;
    },
  });

  const isToShowDeviceList =
    !deviceListIsLoading &&
    !deviceListIsErrored &&
    deviceList &&
    deviceList.length > 0;

  const isToShowEmptyState = deviceList && deviceList.length === 0;
  const isToShowError = !deviceListIsLoading && deviceListIsErrored;

  useEffect(() => {
    paginationActions.tabsActions.setCount(
      paginationState.tabs[0],
      devicesCount?.count || 0
    );
  }, [devicesCount]);

  useEffect(() => {
    paginationActions.tabsActions.setOnClick(paginationState.tabs[0], () => {
      paginationActions.setCurrentPage(0);
      paginationActions.setActiveTab(1);
    });

    // paginationActions.tabsActions.setOnClick(paginationState.tabs[1], () => {
    //   paginationActions.setCurrentPage(0);
    //   paginationActions.setActiveTab(2);
    // });
  }, []);

  useEffect(() => {
    paginationActions.setIsLoading(deviceListIsLoading);
  }, [deviceListIsLoading]);

  return {
    activeTab,
    deviceList,
    deviceListIsErrored,
    deviceListIsLoading,
    paginationState,
    paginationActions,
    tabs: paginationState.tabs,
    devicesCount: devicesCount?.count || 0,
    isToShowDeviceList,
    isToShowEmptyState,
    isToShowError,
  };
};
