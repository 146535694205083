import { GLCard } from "@group-link-one/grouplink-components";

import { GetDevicesActivatedLastReadingsResponse } from "../../../Services/deviceListService/useDeviceListService.types";
import { useDeviceListCard } from "./useDeviceListCard";

interface DeviceListCardProps {
  item: GetDevicesActivatedLastReadingsResponse;
}

export function DeviceListCard({ item }: DeviceListCardProps) {
  const { isMobile, onAddressClick, onDetailsClick } = useDeviceListCard();

  const hasAlerts = item.channels.some((channel) => channel.alerts?.length > 0);

  return (
    <GLCard
      key={item.device_id}
      variant="ut-device-list-card"
      status="active"
      hasAlerts={hasAlerts}
      name={item.channels[0].name || "--"}
      address={item.meta.display_address || "--"}
      id={String(item.device_id)}
      lastEvent={item.last_seen}
      isMobile={isMobile}
      onAddressClick={() => {
        onAddressClick(item.meta.latitude, item.meta.longitude);
      }}
      onOpenDetails={() => onDetailsClick(item)}
    />
  );
}
