import {
  GL_COLORS,
  GL_GLStation,
  GLList,
  GLPaginationComponent,
  GLTypography,
} from "@group-link-one/grouplink-components";

import { DeviceListCard } from "../Cards/DeviceListCard";
import {
  DeviceListAllDevicesContainer,
  DeviceListEmpty,
} from "./DeviceListContentStyle";
import {
  DEVICE_LIST_IPP,
  useDeviceListAllDevices,
} from "./useDeviceListContent";

export function DeviceListContent() {
  const {
    activeTab,
    deviceList,
    devicesCount,
    paginationState,
    paginationActions,
    tabs,
    isToShowDeviceList,
    isToShowEmptyState,
    isToShowError,
    deviceListIsLoading,
  } = useDeviceListAllDevices();

  return (
    <DeviceListAllDevicesContainer gap={30} direction="column" height="100%">
      {!isToShowError && (
        <GLList
          tabs={tabs}
          tabActive={activeTab?.name || ""}
          hasFixedHeaderAnimation={true}
          isLoading={deviceListIsLoading}
          filters={
            <GLPaginationComponent
              currentPage={paginationState.currentPage}
              itemsPerPage={DEVICE_LIST_IPP}
              nextPage={(nextPage) =>
                paginationActions.setCurrentPage(nextPage)
              }
              previousPage={(previousPage) =>
                paginationActions.setCurrentPage(previousPage)
              }
              totalItems={devicesCount}
            />
          }
          content={
            <>
              {isToShowDeviceList &&
                deviceList!.map((item) => (
                  <DeviceListCard key={item.device_id} item={item} />
                ))}

              {isToShowEmptyState && (
                <DeviceListEmpty>
                  <div>
                    <GL_GLStation
                      size={30}
                      fill={GL_COLORS.FONT_COLOR_DARK_GREY}
                    />
                  </div>

                  <GLTypography
                    text="No GL device found"
                    color="FONT_COLOR_DARK_GREY"
                    fontSize={5}
                    weight={600}
                    as="p"
                  />
                </DeviceListEmpty>
              )}
            </>
          }
          type={isToShowEmptyState ? "empty" : "list"}
          listName="Device List"
        />
      )}

      {isToShowError && (
        <DeviceListEmpty>
          <div>
            <GL_GLStation size={30} fill={GL_COLORS.FONT_COLOR_DARK_GREY} />
          </div>

          <GLTypography
            text="No GL device found"
            color="FONT_COLOR_DARK_GREY"
            fontSize={5}
            weight={600}
            as="p"
          />
        </DeviceListEmpty>
      )}
    </DeviceListAllDevicesContainer>
  );
}
